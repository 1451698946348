import React from 'react';
import Helmet from 'react-helmet';

import ProductsPage from '..';

// Query result goes to this.props.data
export const query = graphql`
  query {
    allReseller {
      edges {
        node {
          productId
          title
          price
          duration
          discount_price
          description
          images{url,alt}
          thumbs{url,alt}
          slug
        }
      }
    }
  }
`

const ResellersPage = (props) => {
  const products = props.data.allReseller.edges.map((p) => p.node);

  return (
    <>
      <Helmet>
        <title>Reseller</title>
        <link rel="alternate" href="https://wifirepublic.id/products/reseller/" hrefLang="id" />
        <link rel="canonical" href="https://wifirepublic.id/products/reseller/" />
        <meta name="title" content="Reseller" />
        <meta name="description" content="Internet murah mudah dan cepat" />
        <meta name="thumbnail" content="https://api.wifirepublicid.com/images/logo.png" />
        <meta name="og:title" content="Reseller" />
        <meta name="og:type" content="website" />
        <meta name="og:description" content="Internet murah mudah dan cepat" />
        <meta name="og:image" content="https://api.wifirepublicid.com/images/logo.png" />
        <meta name="og:url" content="https://wifirepublic.id/products/reseller/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Reseller" />
        <meta name="twitter:description" content="Internet murah mudah dan cepat" />
        <meta name="twitter:image" content="https://api.wifirepublicid.com/images/logo.png" />
        <meta name="twitter:url" content="https://wifirepublic.id/products/reseller/" />
        <meta name="twitter:site" content="@wifirepublic" />
        <script src="/jquery.min.js" />
        <script src="/theme.js" />
        <link rel="stylesheet" href="/theme2.css" />
        <link rel="stylesheet" href="/gilroy.css" />
      </Helmet>
      <ProductsPage
        category='reseller'
        products={products}
      />
    </>
  )
};

export default ResellersPage;